
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  AddUserDepartment,
  DepartmentTypes,
} from "@/store/modules/department/department.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const departmentX = namespace("Department");

@Component({
  components: { DatePicker },
})
export default class AddDepartment extends Mixins(ValidationMixin) {
  @departmentX.State(DepartmentTypes.ADD_DEPARTMENT_STATE)
  public addDepartment!: DefaultState;

  @departmentX.Mutation(DepartmentTypes.SET_ADD_DEPARTMENT_DIALOG)
  public setAddDepartment!: (addDepartment: boolean) => void;

  @departmentX.Action(DepartmentTypes.ADD_DEPARTMENT)
  public saveDepartment!: (farmer: AddUserDepartment) => Promise<void>;

  public addDepartmentStep = 1;

  public isValid = false;

  public department: AddUserDepartment = {
    name: "",
    description: "",
    active: true,
  };

  @Ref("form") public formRef!: Validator;

  closeAddDepartmentDialog(): void {
    this.setAddDepartment(false);
  }

  async saveDepartmentToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveDepartment(this.department);
    }
  }

  // mounted(): void {}
}
